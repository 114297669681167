<template>
	<div class="lib-button1">
		<button @click="$attrs ? $attrs.onOnClick() : null" :type="type"><slot></slot></button>
	</div>
</template>

<script>
export default {
	props: {
		type: {
			default: "button",
			type: String,
		},
	},
};
</script>

<style lang="scss">
.lib-button1 {
	button {
		color: #ffffff;
		padding: 7px;
		cursor: pointer;
		font-size: 10.7936px;
		line-height: 16px;
		width: 136px;
		height: 38.32px;
		background-color: #f88f1f;
		background-image: linear-gradient(
			120deg,
			#eb1b24 -26.95%,
			#ee3423 -11.81%,
			#f15622 12.49%,
			#f47120 37.33%,
			#f68420 62.57%,
			#f88f1f 88.49%,
			#f8931f 116.08%
		);
		border-radius: 2.69841px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-size: 200% 100%;
		background-position: 100% 0;
		transition: background-position 0.5s infinite;
		-webkit-transition: background-position 0.5s infinite;
		-moz-transition: background-position 0.5s infinite;
		-o-transition: background-position 0.5s infinite;
		&:hover {
			background-color: #f47120;
			background-position: 0 0;
		}
	}
}

@media (min-width: 390px) {
	.lib-button1 {
		button {
			font-size: 14.1631px;
			line-height: 21px;
			border-radius: 3.54079px;
			width: 178.46px;
			height: 50.28px;
		}
	}
}

@media (min-width: 640px) {
	.lib-button1 {
		button {
			font-size: 23.2421px;
			line-height: 35px;
			border-radius: 5.81052px;
			width: 292.85px;
			height: 82.51px;
		}
	}
}

@media (min-width: 768px) {
	.lib-button1 {
		button {
			width: 351.42px;
			height: 99.01px;
			border-radius: 6.97262px;
			font-size: 27.8905px;
			line-height: 42px;
		}
	}
}

@media (min-width: 1025px) {
	.lib-button1 {
		button {
			border-radius: 5px;
			width: 252px;
			height: 71px;
			font-size: 20px;
			line-height: 30px;
		}
	}
}


</style>
