<template>
    <Header />
    <slot></slot>
    <Footer />
    <FullScreen v-if="fullscreen"  />
</template>

<script>
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import FullScreen from '@/components/FullScreen';

export default {
    components: {Header, Footer, FullScreen},
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.setWidth);

            let hash = window.location.hash.slice(1);
            try {
                const el = document.querySelector(`#${hash}`);
                if (el) {
                       el.scrollIntoView({block: 'end', behavior: 'smooth'});
                }
            } catch (err) {
                // Ignore error
            }
        });
    },

    computed: {
        fullscreen() {
            return this.$store.state.fullscreen;
        }
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.setWidth);
    },

    methods: {
        setWidth() {
            this.$store.commit('setScreenWidth');
        },
    },
};
</script>

<style lang="scss">
body {
}

#app {
    width: 100%;
    display: flex;
    flex-direction: column;
}
</style>
