<template>
	<span :class="arrowType" class="arrow">
		<a href="javascript:void(0)" @click.prevent="$attrs.onOnClick && $attrs.onOnClick()">
			<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
				<circle cx="32.1599" cy="32.3708" r="31.3747" />
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M29.2015 21.2122L39.8115 31.2545C40.4625 31.8707 40.4625 32.8698 39.8115 33.4861L29.2015 43.5283C28.5505 44.1446 27.4949 44.1446 26.8438 43.5283C26.1927 42.9121 26.1927 41.913 26.8438 41.2967L36.2748 32.3703L26.8438 23.4438C26.1927 22.8276 26.1927 21.8284 26.8438 21.2122C27.4949 20.5959 28.5505 20.5959 29.2015 21.2122Z"
					fill="#F1F1F1"
				/>
			</svg>
		</a>
	</span>
</template>
<script>
export default {
	props: {
		arrowType: {
			type: String,
			default: '',
		},
	},
};
</script>
<style scoped lang="scss">
span.arrow {
	z-index: 999;
	a {
		border-radius: 50%;
		overflow: hidden;
		background-color: #f88f1f;
		background-image: linear-gradient(120deg, #eb1b24 -26.95%, #ee3423 -11.81%, #f15622 12.49%, #f47120 37.33%, #f68420 62.57%, #f88f1f 88.49%, #f8931f 116.08%);
		background-size: 200% 100%;
		background-position: 100% 0;
		transition: background-position 0.5s infinite;
		 -webkit-transition: background-position 0.5s infinite;
  -moz-transition: background-position 0.5s infinite;
  -o-transition: background-position 0.5s infinite;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            -webkit-tap-highlight-color: transparent;
		svg {
			border-radius: 50%;
		}
		&:hover {
			background-color: #f47120;
			background-position: 0 0;
		}
	}
}
span.mobile {
a {
	display: flex;
}
}
span.desktop {
	position: absolute;
	top: 0;
	bottom: 0;
	align-items: center;
	display: none;
}

@media (min-width: 1280px) {
	span {
		display: none;
	}
	span.desktop {
		right: -70px;
		display: flex;
	}
}

@media (min-width: 1300px) {
	span.desktop {
		right: -90px;
	}
}
</style>
