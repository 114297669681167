<template>
    <div class="products-wrapper">
        <div style="padding-top: 47px" class="separator"></div>
        <section id="our-products" class="lib-paddings">
            <div style="width: 70%; margin-left: auto; z-index: 999" class="separator addboxshadow"></div>
            <div class="blur1 blur"></div>
            <div class="blur2 blur"></div>
            <Text1 class="text-center">
                <span class="lib-color-darkblue">Our</span>
                <span class="lib-color-orange"> Products</span>
            </Text1>
            <div class="flex justify-center relative">
                <div class="products" :class="[!showMore && 'showLess']" ref="products">
                    <template v-for="(item, i) in productComputed">
                        <div class="product" :class="[!showMore && item.addedClass]">
                            <div class="img">
                                <img :src="require(`@/assets/products/${item.image}`)" :alt="item.name" />
                            </div>
                            <p class="title text-center">{{ item.name }}</p>
                        </div>
                    </template>

                    <ArrowRight @onClick="move(1)" class="product-arrow-right desktop" />
                    <ArrowLeft @onClick="move(-1)" class="product-arrow-left desktop" />
                </div>
            </div>
            <OurBrands />
        </section>
    </div>
</template>
<script>
import Text1 from '@/components/Text1';
import ArrowLeft from '@/components/ArrowLeft';
import ArrowRight from '@/components/ArrowRight';
import OurBrands from './OurBrands';

export default {
    components: {Text1, ArrowRight, ArrowLeft, OurBrands},

    data() {
        return {
            fullscreen: '',
            products: [
                {
                    name: 'BX Cable',
                    image: 'BX Cable.png',
                },
                {
                    name: 'Bedroom Fixtures',
                    image: 'Bedroom Fixtures.jpeg',
                },
                {
                    name: 'MCM Cable',
                    image: 'MCM Cable.png',
                },
                {
                    name: 'Meter Pan',
                    image: 'Meter Pan.png',
                },
                {
                    name: 'Panels with breakers',
                    image: 'Electrical Panels with breakers.png',
                },
                {
                    name: 'Dimmers',
                    image: 'Dimmers.png',
                },
                {
                    name: 'Service Centers',
                    image: 'Electrical Service Centers.png',
                },
                {
                    name: 'Switches',
                    image: 'Switches.png',
                },
                {
                    name: 'Outlets',
                    image: 'Outlets.png',
                },
                {
                    name: 'THHN Wire',
                    image: 'THHN Wire.png',
                },
                {
                    name: 'Spot Lights',
                    image: 'Spot Lights.jpeg',
                },
                {
                    name: 'Strip Lights',
                    image: 'Strip Lights.png',
                },
                {
                    name: 'Chandeliers',
                    image: 'Chandeliers.png',
                },
                {
                    name: 'Disconnects',
                    image: 'Disconnects.png',
                },
                {
                    name: 'LED Bulbs',
                    image: 'LED Bulbs.png',
                },
            ],
        };
    },

    computed: {
        showMore() {
            return this.$store.state.showMore;
        },
        screenWidth() {
            return this.$store.state.screenWidth;
        },

        perpage() {
            let screenWidth = this.screenWidth;

            let perpage = 1;
            if (screenWidth >= 1280) {
                perpage = 4;
            } else if (screenWidth >= 867) {
                perpage = 3;
            } else if (screenWidth >= 640) {
                perpage = 2;
            }
            return perpage;
        },

        page: {
            get() {
                return this.$store.state.productPage;
            },
            set(v) {
                this.$store.commit('setProductPage', v);
            },
        },

        slide: {
            get() {
                return this.$store.state.productSlide;
            },
            set(v) {
                this.$store.commit('setProductSlide', v);
            },
        },

        productComputed() {
            const startAt = (this.page - 1) * this.perpage;
            let cloned = JSON.parse(JSON.stringify(this.products));
            let cloned1 = JSON.parse(JSON.stringify(this.products));
            const filtered = cloned.slice(startAt, startAt + this.perpage);

            const products = cloned1.map((product) => {
                if (!filtered.find((item) => item.name === product.name)) {
                    product.addedClass = 'hide-element';
                } else {
                    product.addedClass = this.slide;
                }
                return product;
            });

            return products;
        },
    },

    methods: {
        move(v) {
            if (v === 1) {
                this.slide = 'lib-slide-in';
            } else {
                this.slide = 'lib-slide-out';
            }

            let page = this.page;
            const maxPage = Math.ceil(this.products.length / this.perpage);
            page = this.page + v;

            if (page < 1) {
                this.page = maxPage;
            } else if (page > maxPage) {
                this.page = 1;
            } else {
                this.page = page;
            }
        },
    },
};
</script>

<style scoped lang="scss">
// Code for swipe

section#our-products {
    .products {
        overflow: visible;
        padding-right: 20px;
        padding-left: 20px;
        width: 1102px;
        display: flex;
        position: relative;
        justify-content: center;
        .product {
            width: 100%;
            max-width: 241.65px !important;
            position: relative !important;
            margin-right: 0;
            margin-left: 0;
            .img {
                // height: 241.65px !important;
            }
        }
        .product-arrow-right,
        .product-arrow-left {
            display: none !important;
        }
    }
    .products:not(.showLess) {
        flex-wrap: wrap;
    }
    :deep(h2) {
        z-index: 99999;
    }
}

.separator {
    display: none;
}
.products-wrapper {
    overflow: hidden;
    background: #f8f8f8;
}

section#our-products {
    position: relative;
    z-index: 99;
    background: #f8f8f8;
    .blur {
        position: absolute;
        background: rgba(225, 111, 31, 0.39);
        mix-blend-mode: multiply;
        opacity: 0.39;
        filter: blur(149.142px);
        pointer-events: none;
        transform: rotate(-135deg);
    }
    .blur2 {
        width: 40vw;
        height: 40vw;
        bottom: 5vw;
        right: 0px;
    }
    .blur1 {
        width: 40vw;
        height: 40vw;
        left: 10vw;
        top: 5vw;
    }

    .products {
        padding-top: 30px;
        align-items: center;
        padding-bottom: 30px;

        .product {
            border-radius: 17.8568px;
            background: #ffffff;
            box-shadow: 0px 2.72172px 19.7324px rgba(0, 0, 0, 0.25);
            z-index: 100;
            margin: 11.5px 0;
            img {
                padding: 2px;
                width: 100%;
                border-radius: 17.8568px 17.8568px 0 0;
                // height: 100%;
                // height: 240px;
            }
            .title {
                width: 100%;

                padding: 16px 23px 16px;
                font-weight: 700;
                font-size: 19.052px;
                line-height: 164.5%;
                white-space: nowrap;
                overflow: hidden;
                color: #0f0d3f;
                // text-overflow: ellipsis;
                border-top: 0.840234px solid #e6e6e6;
            }
        }
        span {
            display: none;
        }
    }
}
hr {
    border-top: 1px solid #d7d7d7;
    height: 0;
}

@media (min-width: 390px) {
    section#our-products {
        .products {
            padding-bottom: 40px;
            .product {
                margin: 11.5px;
                min-width: 241.65px;
                .img {
                    height: 241.65px !important;
                }
            }
        }
    }
}

@media (min-width: 640px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1025px) {
    section#our-products {
        .blur2 {
            width: 20vw;
            height: 20vw;
            bottom: 100px;
            right: 0px;
        }
        .blur1 {
            width: 15vw;
            height: 15vw;
            left: 96.37px;
            top: 200px;
        }
    }
}

@media (min-width: 1280px) {
    .addboxshadow {
        -webkit-appearance: none;
        -webkit-box-shadow: 0px 0px 60px 80px rgba(241, 241, 241, 1);
        -moz-box-shadow: 0px 0px 60px 80px rgba(241, 241, 241, 1);
        box-shadow: 0px 0px 60px 80px rgba(241, 241, 241, 1);
    }
    .products-wrapper {
        overflow: visible;
    }
    section#our-products {
        .products {
            padding-top: 53.54px;
            padding-right: 0px !important;
            padding-left: 0px !important;
        }
        .products.showLess {
            .product {
                display: block;
            }
            span {
                display: flex !important;
            }
        }
    }

    .separator {
        display: block;
    }
    .lib-paddings {
        padding-top: 20px;
    }
}

.hide-element {
    display: none !important;
}

@media (min-width: 1536px) {
}
</style>
