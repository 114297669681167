<template>
	<span :class="arrowType" class="arrow">
		<a href="javascript:void(0)" @click.prevent="$attrs.onOnClick && $attrs.onOnClick()">
			<svg width="64" height="63" viewBox="0 0 64 63" fill="none" xmlns="http://www.w3.org/2000/svg">
				<circle cx="32.3714" cy="31.3753" r="31.3747" transform="rotate(180 32.3714 31.3753)"  />
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M35.3297 42.5339L24.7198 32.4916C24.0687 31.8754 24.0687 30.8763 24.7198 30.26L35.3297 20.2178C35.9808 19.6015 37.0364 19.6015 37.6875 20.2178C38.3386 20.834 38.3386 21.8331 37.6875 22.4494L28.2564 31.3758L37.6875 40.3023C38.3386 40.9185 38.3386 41.9177 37.6875 42.5339C37.0364 43.1502 35.9808 43.1502 35.3297 42.5339Z"
					fill="#F1F1F1"
				/>
			</svg>
		</a>
	</span>
</template>
<script>
export default {
	props: {
		arrowType: {
			type: String,
			default: '',
		},
	},
};
</script>
<style scoped lang="scss">
span.arrow {
	z-index: 999;
	a {
		border-radius: 50%;
		overflow: hidden;
		background-color: #f88f1f;
		background-image: linear-gradient(120deg, #eb1b24 -26.95%, #ee3423 -11.81%, #f15622 12.49%, #f47120 37.33%, #f68420 62.57%, #f88f1f 88.49%, #f8931f 116.08%);
		background-size: 200% 100%;
		background-position: 100% 0;
		transition: background-position 0.5s infinite;
		-webkit-transition: background-position 0.5s infinite;
		-moz-transition: background-position 0.5s infinite;
		-o-transition: background-position 0.5s infinite;
		  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            -webkit-tap-highlight-color: transparent;
		svg {
			border-radius: 50%;
		}
		&:hover {
			background-color: #f47120;
			background-position: 0 0;
		}
	}
}
span.mobile {
	a {
		display: flex;
	}
}
span.desktop {
	position: absolute;
	top: 0;
	bottom: 0;
	align-items: center;
	display: none;
}
@media (min-width: 1280px) {
	span {
		display: none;
	}
	span.desktop {
		left: -70px;
		display: flex;
	}
}

@media (min-width: 1300px) {
	span.desktop {
		left: -90px;
	}
}
</style>
