<template>
	<div class="lib-fullscreen">
		<div class="x-button-container">
			<a class="x-button" href="javascript:void(0)" @click.prevent="close">
				<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect x="2.12109" width="27" height="3" transform="rotate(45 2.12109 0)" fill="white" />
					<rect x="21.2148" y="2.12109" width="27" height="3" transform="rotate(135 21.2148 2.12109)" fill="white" />
				</svg>
			</a>
		</div>
		<div class="h-full flex items-center flex-col justify-center" style="padding: 10vh 0; position: relative; min-height: 500px">
			<img
				:class="slide"
				:src="require(`@/assets/projects/${selectedImage}`)"
				:key="imageId"
				@load="
					() => {
						loaded = true;
					}
				"
				style="background: url('@/assets/loaders.gif') no-repeat center"
			/>
			<ArrowRight @onClick="swipeLeft()" class="product-arrow-right desktop" />
			<ArrowLeft @onClick="swipeRight()" class="product-arrow-left desktop" />
			<ArrowContainer>
				<ArrowLeft arrowType="mobile" @onClick="swipeLeft()" />
				<ArrowRight arrowType="mobile" @onClick="swipeRight()" />
			</ArrowContainer>
		</div>
	</div>
</template>
<script>
import ArrowContainer from '@/components/ArrowContainer';
import ArrowLeft from '@/components/ArrowLeft';
import ArrowRight from '@/components/ArrowRight';
export default {
	components: {ArrowContainer, ArrowRight, ArrowLeft},

	data() {
		return {
			start: null,
			end: null,
			slide: null,
			loaded: false,
		};
	},

	unmounted() {
		document.removeEventListener('touchstart', this.touchStart, false);
		document.removeEventListener('touchend', this.touchEnd, false);
	},

	mounted() {
		document.addEventListener('touchstart', this.touchStart, false);
		document.addEventListener('touchend', this.touchEnd, false);
	},

	watch: {
		selectedImage(v) {
			this.loaded = false;
		},
	},

	computed: {
		images() {
			return this.$store.state.projects;
		},
		imageId() {
			return this.$store.state.fullscreen;
		},

		selectedImage() {
			return this.images.find((item) => item.id === this.imageId).image;
		},

		maxIndex() {
			return this.images.length - 1;
		},
	},

	methods: {
		close() {
			this.$store.commit('setFullScreen', null);
		},
		touchEnd(evt) {
			const touch = evt.changedTouches[0];
			this.end = touch.pageX;
			this.touchMove();
		},
		touchStart(evt) {
			const touch = evt.touches || evt.originalEvent.touches;
			this.start = touch[0].pageX;
		},
		touchMove() {
			let distance;

			if (this.start === this.end) {
				return;
			}

			distance = Math.abs(this.start - this.end);

			if (distance < 60) {
				return;
			}

			if (this.start > this.end) {
				this.swipeRight();
			} else {
				this.swipeLeft();
			}

			this.start = null;
			this.end = null;
		},
		getId(index) {
			return this.images[index].id;
		},

		swipeLeft() {
			let index = this.images.findIndex((item) => {
				return item.id === this.imageId;
			});
			index = index - 1;
			// update selected image index
			this.slide = 'lib-slide-out';
			this.selectByIndex(index);
		},

		selectByIndex(index) {
			if (index > this.maxIndex) {
				index = 0;
			} else if (index < 0) {
				index = this.maxIndex;
			}
			this.$store.commit('setFullScreen', this.getId(index));
		},

		swipeRight() {
			let index = this.images.findIndex((item) => {
				return item.id === this.imageId;
			});
			index = index + 1;
			this.slide = 'lib-slide-in';
			this.selectByIndex(index);
		},
	},
};
</script>
<style scoped lang="scss">
.lib-fullscreen {
	overflow: hidden !important;
	position: fixed;
	left: 0;
	top: 0;
	background: rgba(0, 0, 0, 1);
	z-index: 999999999;
	right: 0;
	width: 100vw;
	padding-top: 2vw;
	height: 100vh;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	overscroll-behavior: contain;
	img {
		// width: 900px;
		max-width: 100vw;
		max-height: 60vh;
	}
	:deep(.product-arrow-right) {
		position: fixed;
		left: auto;
		right: 100px;
	}
	:deep(.product-arrow-left) {
		position: fixed;
		right: auto;
		left: 100px;
	}
	.x-button-container {
		z-index: 999999999;
		width: 100%;
		position: fixed;
		top: 0;
		right: 0;
		display: flex;
		justify-content: flex-end;
		a {
			padding-right: 35px;
			padding-top: 35px;
			display: inline-block;
		}
		padding-bottom: 20px;
		svg {
			width: 25px;
			height: 25px;
		}
	}
	:deep(.arrow-container) {
		padding-top: 30px;
	}
}

@media (min-width: 390px) {
	.lib-fullscreen {
		.x-button-container {
			svg {
				width: 27px;
				height: 27px;
			}
		}
	}
}

@media (min-width: 640px) {
	.lib-fullscreen {
		.x-button-container {
			svg {
				width: 29px;
				height: 29px;
			}
		}
	}
}

@media (min-width: 768px) {
	.lib-fullscreen {
		.x-button-container {
			svg {
				width: 27px;
				height: 27px;
			}
		}
	}
}

@media (min-width: 1025px) {
	.lib-fullscreen {
		img {
			max-width: 900px;
			max-height: 80vh;
		}
		.x-button-container {
			svg {
				width: 33px;
				height: 33px;
			}
		}
	}
}
</style>
