<template>
	<div class="flex arrow-container">
		<slot></slot>
	</div>
</template>
<style lang="scss" scoped>
.arrow-container {
	display: grid;
	grid-template-columns: auto auto;
	justify-content: center;
	width: 100%;
	padding-top: 40px;
	column-gap: 12px;
	:deep(svg) {
		width: 50px;
		height: 50px;
	}
}

@media (min-width: 390px) {
	.arrow-container {
		padding-top: 45px;
		column-gap: 20px;
		:deep(svg) {
			width: 55px;
			height: 55px;
		}
	}
}

@media (min-width: 640px) {
	.arrow-container {
		padding-top: 50px;
		column-gap: 25px;
	}
}


@media (min-width: 1280px) {
	.arrow-container {
		display: none;
	}
}

@media (min-width: 1280px) {
	.arrow-container {
		display: none;
	}
}
</style>
