<template>
  <div class="lib-text1">
    <h2><slot></slot></h2>
  </div>
</template>
<style scoped lang="scss">
.lib-text1 h2 {
  font-weight: 700;
  color: white;
  font-size: 24px;
  line-height: 36px;
}

@media (min-width: 390px) {
  .lib-text1 h2 {
    font-size: 31.4922px;
    line-height: 47px;
  }
}

@media (min-width: 640px) {
  .lib-text1 h2 {
    font-size: 51.6795px;
    line-height: 78px;
  }
}

@media (min-width: 768px) {
  .lib-text1 h2 {
    font-size: 62.0153px;
    line-height: 93px;
  }
}

@media (min-width: 1025px) {
  .lib-text1 h2 {
    font-size: 48px;
    line-height: 72px;
  }
}

@media (min-width: 1536px) {
  .lib-text2 h2 {
    font-size: 48px;
    line-height: 72px;
  }
}
</style>
