<template>
    <footer class="">
        <!--   <div class="footer-container">
      <div></div>
      <div><h4>Browse Our Website</h4><ul>
      <li>Our Products</li>
      <li>Lighting Jobs</li>
      <li>Testimonians</li>
      <li>Contact Us</li></ul></div>
      <div><h4>Contact Information</h4> <ul><li>999 999 999</li>
      <li>user@gmai.com</li></ul></div>
    </div> -->
        <div class="footer-copyrights container-1">
            <div class="content content-max-width">
                <div class="copyrights">Copyright 2022&nbsp;<a href="/" style="padding: 0"><b>ELM Lighting</b></a></div>
               
                <div class="inline">
               <a href="https://indentations.io/" target="_blank"><div class="designed-by">Designed @&nbsp;<b>INDENTATIONS</b></div></a>
                </div>
                 <div class="privacy-policy">
                    <a href="javascript:void(0)" @click="$router.push({name: 'policy'})"><b>Privacy Policy</b></a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
footer {
    .footer-container {
        display: flex;
        flex-direction: column;
    }
    .footer-copyrights {
        background: #F8931F;
        .content {
            font-size: 10px;
            line-height: 164.5%;
            color: white;
            display: flex;
            align-items: center;
            row-gap: 7.29px;
            flex-direction: column;
             justify-content: center;
            padding-top: 17px;
            padding-bottom: calc(18px);
            div {
                white-space: nowrap;
                text-align: center;
                width: 100%;
            }
          
        }
    }
}

@media (min-width: 390px) {
    footer {
        .footer-copyrights {
            .content {
                font-size: 12px;
            }
        }
    }
}

@media (min-width: 640px) {
    footer {
        .footer-copyrights {
            .content {
                font-size: 15.0732px;
            }
        }
    }
}

@media (min-width: 768px) {
    footer {
        .footer-copyrights {
            .content {
                font-size: 18.0878px;
            }
        }
    }
}

@media (min-width: 1025px) {
    footer {
        .footer-copyrights {
            .content {
                font-size: 13px;
                flex-direction: row;
                justify-content: space-between;
                div {
                    text-align: left;
                    width: auto;
                    display: flex;
                    a {
                        padding-left: 30px;
                        // text-decoration-line: underline;
                    }
                    br {
                        display: none;
                    }
                }
                  .designed-by {
                text-align: right;
            }
            .copyrights {
                text-align: left;
            }
              
            }
        }
    }
}

@media (min-width: 1536px) {
}
</style>
