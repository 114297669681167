<template>
  <div class="lib-text2">
    <h3><slot></slot></h3>
  </div>
</template>
<style scoped lang="scss">
.lib-text2 h3 {
  font-weight: 700;
  color: white;
  font-size: 18.9269px;
  line-height: 28px;
}

@media (min-width: 390px) {
  .lib-text2 h3 {
    font-size: 24.8354px;
    line-height: 37px;
  }
}

@media (min-width: 640px) {
  .lib-text2 h3 {
    font-size: 40.7555px;
    line-height: 61px;
  }
}

@media (min-width: 768px) {
  .lib-text2 h3 {
    font-size: 48.9065px;
    line-height: 73px;
  }
}

@media (min-width: 1025px) {
  .lib-text2 h3 {
    font-size: 33.253px;
    line-height: 50px;
  }
}

@media (min-width: 1280px) {
  .lib-text2 h3 {
    //     font-size: 48.9065px;
    //     line-height: 73px;
    //     font-size: 33.253px;
    // line-height: 50px;
  }
}

@media (min-width: 1536px) {
  .lib-text2 h3 {
    font-size: 40px;
    line-height: 60px;
  }
}
</style>
