<template>
    <header class="container-1">
        <div class="content-max-width content">
            <a href="/">
                <div class="header-logo">
                    <img src="@/assets/images/logo.svg" alt="Logo" />
                </div>
            </a>
            <div class="mobile">
                <a href="#" class="header-menu" @click.prevent="sidebar = !sidebar">
                    <svg width="27" height="19" viewBox="0 0 27 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="27" height="3" fill="#0F0D3F" />
                        <rect y="8" width="27" height="3" fill="#0F0D3F" />
                        <rect y="16" width="27" height="3" fill="#0F0D3F" />
                    </svg>
                </a>
            </div>

            <div class="desktop">
                <ul>
                    <li>
                        <a href="javascript:void(0)" @mousedown.prevent="scrollTo('our-products', $event)">Our Products</a>
                        <span></span>
                    </li>
                    <li>
                        <a href="javascript:void(0)" @mousedown.prevent="scrollTo('lighting-jobs', $event)">Lighting Jobs</a>
                        <span></span>
                    </li>
                    <li><a href="javascript:void(0)" @mousedown.prevent="scrollTo('testimonials', $event)">Testimonials</a><span></span></li>
                    <li><a href="javascript:void(0)" @mousedown.prevent="scrollTo('contact-us', $event)">Contact Us </a><span></span></li>
                </ul>
            </div>
        </div>
    </header>
    <SideBar :show="sidebar" @close="sidebar = false" />
</template>

<script>
import SideBar from './SideBar';
let lock = true;
let scrollTimeout;

export default {
    components: {SideBar},
    data() {
        return {
            sidebar: false,
            ismounted: false,
        };
    },

    mounted() {
        document.onreadystatechange = () => {
            if (document.readyState == 'complete') {
                lock = null;
            }
        };
    },

    methods: {
        enableScroll() {
            addEventListener('scroll', this.scrollEvent);
        },

        disableScroll() {
            removeEventListener('scroll', this.scrollEvent);
        },
        scrollEvent(e) {
            clearTimeout(scrollTimeout);
            scrollTimeout = setTimeout(() => {
                this.enableWheel();
                this.disableScroll();
                lock = null;
                clearTimeout(scrollTimeout);
                console.log('Finished scroll');
            }, 100);
        },
        scrollTo(id, event) {
            const el = document.getElementById(id);

            if (!lock) {
                this.disableWheel();
                this.enableScroll();
                lock = true;
                window.scrollTo(0, 0);

                setTimeout(() => {
                    lock = null;
                    console.log('Start scroll');
                    el.scrollIntoView({behavior: 'smooth'});
                }, 200);
            }
        },

        preventScroll(e) {
            e.preventDefault();
            e.stopPropagation();
            return false;
        },

        disableWheel() {
            document.body.addEventListener('wheel', this.preventScroll, {passive: false});
        },

        enableWheel() {
            document.body.removeEventListener('wheel', this.preventScroll, {passive: false});
        },
    },
};
</script>

<style scoped lang="scss">
header {
    z-index: 3;
    background: #fff;
    .content {
        justify-content: space-between;
        align-items: center;
        display: flex;
        width: 100%;

        .mobile {
            display: flex;
        }
        .desktop {
            display: none;
        }
    }

    ul {
        li {
            span {
                height: 3px;
                background: transparent;
                display: block;
                width: 0;
                transition: width 0.1s ease-out;
            }
            a {
                padding-top: 4px;
                padding-bottom: 4px;
            }
            a:hover,
            a:active,
            a:visited,
            a:focus {
                color: #f47120;
            }
            a:active + span {
                width: 100%;
                background: linear-gradient(40.17deg, #eb1b24 -26.95%, #ee3423 -11.81%, #f15622 12.49%, #f47120 37.33%, #f68420 62.57%, #f88f1f 88.49%, #f8931f 116.08%);
            }
        }
    }
    .content {
        height: 71px;
    }
    .header-logo {
        img {
            width: 69.01px;
            height: 36.2px;
        }
    }
    .header-menu {
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            -webkit-tap-highlight-color: transparent;
        svg {
            height: 19px;
            width: 27px;
        }
    }
}

@media (min-width: 390px) {
}

@media (min-width: 640px) {
    header {
        .content {
            height: 116.51px;
        }
        .header-logo {
            img {
                height: 59.39828109741211px;
                width: 113.23897552490234px;
            }
        }
        .header-menu {
            svg {
                height: 31.180889129638672px;
                width: 44.30769348144531px;
            }
        }
    }
}

@media (min-width: 768px) {
    header {
        .content {
            height: 139.82px;
        }
        .header-logo {
            img {
                height: 71.27949523925781px;
                width: 135.88754272460938px;
            }
        }
        .header-menu {
            svg {
                height: 37.415504455566406px;
                width: 53.16923141479492px;
            }
        }
    }
}

@media (min-width: 1025px) {
    header {
        .content {
            height: 129px;
            .mobile {
                display: none;
            }
            .desktop {
                display: flex;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                ul {
                    display: flex;
                    position: relative;
                    column-gap: 50px;
                    // right: 176px;
                    li {
                        // width: 194.46px;
                        white-space: nowrap;
                        height: 24px;
                        text-align: center;
                        a {
                            color: #434343;
                            height: 100%;
                            width: 100%;
                        }
                    }
                }
            }
        }
        .header-logo {
            img {
                height: 70.29663848876953px;
                width: 134.00286865234375px;
            }
        }
        .header-menu {
            svg {
                height: 37.415504455566406px;
                width: 53.16923141479492px;
            }
        }
    }
}
@media (min-width: 1280px) {
}
</style>
