<template>
    <aside :class="showMore && 'showMore'">
        <div class="flex justify-center brands-wrapper">
            <div class="brands lib-hide-scroll slider" ref="brands">
                <div class="splide hide-on-showmore" aria-label="Basic Structure Example">
                    <div class="splide__track">
                        <ul class="splide__list">
                            <template v-for="item in brands">
                                <li class="splide__slide brand"><img :src="require(`@/assets/brands/${item.autoScrollImage || item.image}`)" :alt="item.name" style="display: inline" /></li>
                            </template>
                        </ul>
                    </div>
                </div>

                <template v-for="item in brands">
                    <div class="brand show-on-showmore" :class="item.orientation">
                        <img :src="require(`@/assets/brands/${item.image}`)" :alt="item.name" style="display: inline" />
                    </div>
                </template>
            </div>
        </div>

        <ArrowContainer class="lib-mobile">
            <ArrowLeft arrowType="mobile"  class="mobile-product-arrow-left" @onClick="$parent.move(-1)" />
            <ArrowRight arrowType="mobile" class="mobile-product-arrow-right" @onClick="$parent.move(1)" />
        </ArrowContainer>
        <div class="flex justify-center container-button">
            <Button1 @onClick="toggleProducts">{{ showMore ? 'Show Less' : btnText }}</Button1>
        </div>
    </aside>
</template>
<script>
import Button1 from '@/components/Button1';
import Text1 from '@/components/Text1';
import '@splidejs/splide/dist/css/splide.min.css';

import ArrowContainer from '@/components/ArrowContainer';
import ArrowLeft from '@/components/ArrowLeft';
import ArrowRight from '@/components/ArrowRight';
import {AutoScroll} from '@splidejs/splide-extension-auto-scroll';
import Splide from '@splidejs/splide';
let lock;

export default {
    components: {Button1, Text1, ArrowLeft, ArrowRight, ArrowContainer},

    data() {
        return {
            options: {
                rewind: true,
                type: 'loop',
                autoWidth: true,
                pagination: false,
                arrows: false,
                autoplay: false,
                autoStart: true,
                start: 0,
                autoScroll: {
                    speed: 2,
                    pauseOnHover: false,
                    pauseOnFocus: true,
                },
            },
            brands: [
                {
                    id: 1,
                    image: 'abb.png',
                    name: 'ABB',
                    orientation: 'portrait',
                },
                {
                    id: 2,
                    image: 'airzone.png',
                    name: 'Airzone',
                    orientation: 'portrait',
                },
                {
                    id: 3,
                    image: 'ammo.png',
                    name: 'Ammo',
                    orientation: 'portrait',
                },
                {
                    id: 4,
                    image: 'amsterdam.png',
                    name: 'Amsterdam',
                    orientation: 'portrait',
                },
                {
                    id: 6,
                    image: 'astra-lite.png',
                    name: 'Astra Lite',
                    orientation: 'portrait',
                },

                {
                    id: 6,
                    image: 'atkore.png',
                    name: 'Atkore',
                    orientation: 'portrait',
                },

                {
                    id: 7,
                    image: 'eg-sons-sales-inc.png',
                    name: 'E.G.& Sons Sales Inc',
                    orientation: 'portrait',
                },
                {
                    id: 8,
                    image: 'g-box.png',
                    name: 'G-Box',
                    orientation: 'portrait',
                },
                {
                    id: 9,
                    image: 'global-manufacturing.png',
                    name: 'Global Manufacturing',
                    orientation: 'portrait',
                },
                {
                    id: 10,
                    image: 'goodlite.png',
                    name: 'Goodlite',
                    orientation: 'portrait',
                },
                {
                    id: 11,
                    image: 'green-beam.png',
                    name: 'Green Beam',
                    orientation: 'portrait',
                },
                {
                    id: 12,
                    image: 'illumicare.png',
                    name: 'illumicare',
                    orientation: 'portrait',
                },
                {
                    id: 13,
                    image: 'itm.png',
                    name: 'ITM',
                    orientation: 'portrait',
                },
                {
                    id: 14,
                    image: 'kd-lighting.png',
                    name: 'KD Lighting',
                    orientation: 'portrait',
                },
                {
                    id: 15,
                    image: 'kimma.png',
                    name: 'KIMMA',
                    orientation: 'portrait',
                },

                {
                    id: 16,
                    image: 'lbusa.png',
                    name: 'lbusa.png',
                    orientation: 'portrait',
                },

                {
                    id: 17,
                    image: 'lotus-led-lights.png',
                    name: 'Lotus Led Lights',
                    orientation: 'portrait',
                },
                {
                    id: 18,
                    image: 'lutron.png',
                    name: 'Lutron',
                    orientation: 'portrait',
                },
                {
                    id: 19,
                    image: 'luxrite.png',
                    name: 'Luxrite',
                    orientation: 'portrait',
                },

                {
                    id: 20,
                    image: 'murray.png',
                    name: 'Murray',
                    orientation: 'portrait',
                },
                {
                    id: 21,
                    image: 'nav-tech.png',
                    name: 'Nav-Tech',
                    orientation: 'portrait',
                },
                {
                    id: 22,
                    image: 'nora-lighting.png',
                    name: 'Nora Lighting',
                    orientation: 'portrait',
                },
                {
                    id: 23,
                    image: 'panasonic.png',
                    name: 'Panasonic',
                    orientation: 'portrait',
                },
                {
                    id: 24,
                    image: 'pure-edge-lighting.png',
                    name: 'Pure Edge Lighting',
                    orientation: 'portrait',
                },
                {
                    id: 25,
                    image: 'rab.png',
                    name: 'RAB',
                    orientation: 'portrait',
                },
                {
                    id: 26,
                    image: 'satco.png',
                    name: 'SATCO',
                    orientation: 'portrait',
                },
                {
                    id: 27,
                    image: 'siemens.png',
                    name: 'Siemens',
                    orientation: 'portrait',
                },

                {
                    id: 28,
                    image: 'south-conduit.png',
                    name: 'South Conduit',
                    orientation: 'portrait',
                },
                {
                    id: 29,
                    image: 'steel-electric-products.png',
                    name: 'Steel Electric Products',
                    orientation: 'portrait',
                },

                {
                    id: 30,
                    image: 'sunlite.png',
                    name: 'Sunlite',
                    orientation: 'portrait',
                },
                {
                    id: 31,
                    image: 'topaz.png',
                    name: 'Topaz',
                    orientation: 'portrait',
                },
                {
                    id: 32,
                    image: 'universal.png',
                    name: 'Universal',
                    orientation: 'portrait',
                },

                {
                    id: 33,
                    image: 'visual-comfort-co.png',
                    name: 'Visual Comfort & Co',
                    orientation: 'portrait',
                },
                {
                    id: 34,
                    image: 'wac-lighting.png',
                    name: 'Wac Lighting',
                    orientation: 'portrait',
                },

                {
                    id: 35,
                    image: 'warmup.png',
                    name: 'Warmup',
                    orientation: 'portrait',
                },

                {
                    id: 36,
                    image: 'warshaw-incorporated.png',
                    name: 'Warshaw Incorporated',
                    orientation: 'portrait',
                },

                {
                    id: 37,
                    image: 'colonial-wire-cable-co.png',
                    name: 'Colonial Wire & Cable Co.',
                    autoScrollImage: 'colonial-wire-cable-co-autoscroll.png',
                    orientation: 'landscape',
                },
                {
                    id: 38,
                    image: 'control-supply-corp.png',
                    name: 'Control Supply Corp',
                    autoScrollImage: 'control-supply-corp-autoscroll.png',
                    orientation: 'landscape',
                },
            ],
        };
    },

    watch: {
        screenWidth(v) {
            if (!lock) {
                clearTimeout(lock);
                lock = setTimeout(() => {
                    this.$store.commit('toggleProducts', false);
                    lock = null;
                }, 50);
            }
        },
    },

    computed: {
        showMore() {
            return this.$store.state.showMore;
        },
        btnText() {
            if (this.screenWidth >= 1280) {
                return 'Show More';
            } else {
                return 'View All';
            }
        },

        screenWidth() {
            return this.$store.state.screenWidth;
        },

        page: {
            get() {
                return this.$store.state.productPage;
            },
            set(v) {
                this.$commit('setProductPage', v);
            },
        },

        brandsComputed() {
            let screenwidth = this.screenWidth;
            let perpage = 2;

            if (screenwidth >= 1280) {
                perpage = 4;
            } else if (screenwidth >= 768) {
                perpage = 4;
            } else if (screenwidth >= 640) {
                perpage = 3;
            }

            const startAt = (this.page - 1) * perpage;
            let cloned = JSON.parse(JSON.stringify(this.brands));
            let cloned1 = JSON.parse(JSON.stringify(this.brands));
            const filtered = cloned.slice(startAt, startAt + perpage);

            const brands = cloned1.map((brand) => {
                if (!filtered.find((item) => item.id === brand.id)) {
                    brand.addedClass = 'hide-element';
                }
                return brand;
            });

            return brands;
        },

        slide: {
            get() {
                return this.$store.state.productSlide;
            },
            set(v) {
                this.$store.commit('setProductSlide', v);
            },
        },
    },

    mounted() {
        // let browserName = '';

        // if (navigator.vendor.match(/google/i)) {
        //     browserName = 'chrome/blink';
        // } else if (navigator.vendor.match(/apple/i)) {
        //     browserName = 'safari/webkit';
        // } else if (navigator.userAgent.match(/firefox\//i)) {
        //     browserName = 'firefox/gecko';
        // } else if (navigator.userAgent.match(/edge\//i)) {
        //     browserName = 'edge/edgehtml';
        // } else if (navigator.userAgent.match(/trident\//i)) {
        //     browserName = 'ie/trident';
        // } else {
        //     browserName = navigator.userAgent + '\n' + navigator.vendor;
        // }

        let carousel = new Splide('.splide', this.options);
        carousel.options.speed = 2000;
        carousel.mount({AutoScroll});
    },
    methods: {
        toggleProducts() {
            if (this.screenWidth >= 1280) {
                this.$store.commit('toggleProducts');

                this.$nextTick(() => {
                    if (!this.showMore) {
                        const element = document.getElementById('our-products');
                        element.scrollIntoView({behavior: 'instant'});
                    }
                });
            } else {
                this.$router.push('products');
            }
        },
        // initGrabSlider() {
        //     const slider = this.$refs.brands;
        //     let isDown = false;
        //     let startX;
        //     let scrollLeft;

        //     slider.addEventListener('mousedown', (e) => {
        //         isDown = true;
        //         slider.classList.add('active');
        //         startX = e.pageX - slider.offsetLeft;
        //         scrollLeft = slider.scrollLeft;
        //     });
        //     slider.addEventListener('mouseleave', () => {
        //         isDown = false;
        //         slider.classList.remove('active');
        //     });
        //     slider.addEventListener('mouseup', () => {
        //         isDown = false;
        //         slider.classList.remove('active');
        //     });
        //     slider.addEventListener('mousemove', (e) => {
        //         if (!isDown) return;
        //         e.preventDefault();
        //         const x = e.pageX - slider.offsetLeft;
        //         const walk = (x - startX) * 3; //scroll-fast
        //         slider.scrollLeft = scrollLeft - walk;
        //     });
        // },

        // updateSort() {
        //     const el = this.$refs.brands;

        //     let scrollWidth = el.scrollWidth;
        //     let scrollLeft = el.scrollLeft;
        //     let width = el.offsetWidth;
        //     let items = el.children;

        //     if (scrollLeft + screen.width === scrollWidth) {
        //         el.append(items[0]);
        //         el.scrollBy({left: screen.width});
        //     } else if (scrollLeft === 0) {
        //         el.prepend(items[items.length - 1]);
        //         el.scrollBy({left: 10});
        //     }
        // },

        // initinfiniteScroll() {
        //     const div = this.$refs.brands;

        //     let rect = div.getBoundingClientRect();

        //     function updateSort(el) {
        //         let scrollWidth = el.scrollWidth;
        //         let scrollLeft = el.scrollLeft;
        //         let width = el.offsetWidth;
        //         let items = el.children;

        //         if (scrollLeft + screen.width === scrollWidth) {
        //             el.append(items[0]);
        //             el.scrollBy({left: screen.width});
        //         } else if (scrollLeft === 0) {
        //             el.prepend(items[items.length - 1]);
        //             el.scrollBy({left: 10});
        //         }
        //     }

        //     let lastscroll;
        //     div.addEventListener('scroll', function () {
        //         let el = this;
        //         if (lastscroll) {
        //             clearTimeout(lastscroll);
        //         }
        //         lastscroll = setTimeout(function () {
        //             updateSort(el);
        //         }, 50);
        //     });
        //     updateSort(div);
        // },
    },
};
</script>

<style scoped lang="scss">
aside {
    background: transparent;
    .brands {
        width: auto;
        justify-content: center;
        white-space: nowrap;
        max-width: calc(1102px - (11.5px * 2));
        overflow: hidden;
        .hide-element {
            display: none !important;
        }
        &:focus,
        &:active {
            .brand {
                // animation-play-state: paused;
            }
        }
        .brand {
            z-index: 9;
            display: inline-block;
            justify-content: center;
            position: relative;
        }
        .show-on-showmore {
            display: none;
        }
        img {
            padding: 1px;
            border-radius: 0.2rem;
            background: white;
            overflow: hidden;
            height: 100px;
            width: auto;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -o-user-select: none;
            user-select: none;
            pointer-events: none;
        }
        .splide__slide {
            will-change: transform;
        }
        .splide__slide.is-active {
            transform: scale(1);
            transition: transform 0.5s 0.4s;
        }
    }
    .container-button {
        padding-top: 41.6px;
    }
}

aside.showMore {
    .brands {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 1300px;
        padding-right: 20px;
        padding-left: 20px;
        .brand {
            // -webkit-animation: none !important;
            // -moz-animation: none !important;
            // -o-animation: none !important;
            // animation: none !important;
            // -o-animation: none !important;
        }
    }
    .hide-on-showmore {
        display: none;
    }
    .show-on-showmore {
        display: flex;
    }
    .splide {
        display: none;
    }
}

@media (max-width: 301px) {
    // @keyframes move-mobile {
    //     0% {
    //         left: 0;
    //     }
    //     100% {
    //         left: calc(((5px * 38) + (100px * 37) + (210px * 2)) * -1);
    //     }
    // }
    aside {
        .brands {
            .brand {
                // animation: move-mobile 76s linear infinite;
                // -webkit-animation: move-mobile 76s linear infinite;
                // -moz-animation: move-mobile 76s linear infinite;
                // -ms-animation: move-mobile 76s linear infinite;
                // -o-animation: move-mobile 76s linear infinite;

                margin: 5px;
                width: 100px;
                height: calc(((100px) * (138 / 216)));
                justify-content: center;
                align-content: center;
                img {
                    width: 100px;
                    height: calc(((100px) * (138 / 216)));
                }
            }
            .landscape {
                width: calc(200px + 10px);
                img {
                    width: calc(200px + 10px);
                    height: calc(((100px) * (138 / 216)));
                }
            }
        }
    }
    .showMore {
        .brands {
        }
    }
}

@media (min-width: 302px) {
    // @keyframes move-mobile {
    //     0% {
    //         left: 0;
    //     }
    //     100% {
    //         left: calc(((5px * 38) + (120px * 37) + (210px * 2)) * -1);
    //     }
    // }
    aside {
        .brands {
            .brand {
                // animation: move-mobile 76s linear infinite;
                // -webkit-animation: move-mobile 76s linear infinite;
                // -moz-animation: move-mobile 76s linear infinite;
                // -ms-animation: move-mobile 76s linear infinite;
                // -o-animation: move-mobile 76s linear infinite;

                margin: 5px;
                width: 120px;
                height: calc(((120px) * (138 / 216)));
                justify-content: center;
                align-content: center;
                img {
                    width: 120px;
                    height: calc(((120px) * (138 / 216)));
                }
            }
            .landscape {
                width: calc(240px + 10px);
                img {
                    width: calc(240px + 10px);
                    height: calc(((120px) * (138 / 216)));
                }
            }
        }
    }
    .showMore {
        .brands {
        }
    }
}

@media (min-width: 385px) {
    // @keyframes move-mobile {
    //     0% {
    //         left: 0;
    //     }
    //     100% {
    //         left: calc(((7.5px * 38) + (150px * 37) + (315px * 2)) * -1);
    //     }
    // }
    aside {
        .brands {
            .brand {
                // animation: move-mobile 76s linear infinite;
                // -webkit-animation: move-mobile 76s linear infinite;
                // -moz-animation: move-mobile 76s linear infinite;
                // -ms-animation: move-mobile 76s linear infinite;
                // -o-animation: move-mobile 76s linear infinite;
                margin: 7.5px;
                width: 150px;
                height: calc(((150px) * (138 / 216)));
                img {
                    width: 150px;
                    height: calc(((150px) * (138 / 216)));
                }
            }
            .landscape {
                width: calc(300px + 15px);
                img {
                    width: calc(300px + 15px);
                    height: calc(((150px) * (138 / 216)));
                }
            }
        }
    }
}

@media (min-width: 640px) {
    // @keyframes move-mobile {
    //     0% {
    //         left: 0;
    //     }
    //     100% {
    //         left: calc(((9px * 38) + (200px * 37) + (418px * 2)) * -1);
    //     }
    // }
    aside {
        .brands {
            .brand {
                // animation: move-mobile 76s linear infinite;
                // -webkit-animation: move-mobile 76s linear infinite;
                // -moz-animation: move-mobile 76s linear infinite;
                // -ms-animation: move-mobile 76s linear infinite;
                // -o-animation: move-mobile 76s linear infinite;
                margin: 9px;
                width: 200px;
                height: calc(((200px) * (138 / 216)));
                img {
                    width: 200px;
                    height: calc(((200px) * (138 / 216)));
                }
            }
            .landscape {
                width: calc(400px + 18px);
                img {
                    width: calc(400px + 18px);
                    height: calc(((200px) * (138 / 216)));
                }
            }
        }
    }
}

@media (min-width: 768px) {
    // @keyframes move-mobile {
    //     0% {
    //         left: 0;
    //     }
    //     100% {
    //         left: calc(((9px * 38) + (200px * 37) + (418px * 2)) * -1);
    //     }
    // }
    aside {
        .brands {
            .brand {
                // animation: move-mobile 76s linear infinite;
                // -webkit-animation: move-mobile 76s linear infinite;
                // -moz-animation: move-mobile 76s linear infinite;
                // -ms-animation: move-mobile 76s linear infinite;
                // -o-animation: move-mobile 76s linear infinite;
                width: 200px;
                height: calc(((200px) * (138 / 216)));
                img {
                    width: 200px;
                    height: calc(((200px) * (138 / 216)));
                }
            }
            .landscape {
                width: calc(418px);
                img {
                    width: calc(418px);
                    height: calc(((200px) * (138 / 216)));
                }
            }
        }
    }
}

@media (min-width: 1025px) {
    // @keyframes move-mobile {
    //     0% {
    //         left: 0;
    //     }
    //     100% {
    //         left: calc(((9px * 38) + (250px * 37) + (518px * 2)) * -1);
    //     }
    // }
    aside {
        .brands {
            .brand {
                // animation: move-mobile 76s linear infinite;
                // -webkit-animation: move-mobile 76s linear infinite;
                // -moz-animation: move-mobile 76s linear infinite;
                // -ms-animation: move-mobile 76s linear infinite;
                // -o-animation: move-mobile 76s linear infinite;
                width: 250px;
                height: calc(((250px) * (138 / 216)));
                img {
                    width: 250px;
                    height: calc(((250px) * (138 / 216)));
                }
            }
            .landscape {
                width: calc(518px);
                img {
                    width: calc(518px);
                    height: calc(((250px) * (138 / 216)));
                }
            }
        }
    }
}
</style>
