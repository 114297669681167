<template>
	<div class="lib-button2">
		<button :type="type" :disabled="disabled"><slot></slot></button>
	</div>
</template>

<script>
export default {
	props: {
		disabled: {
			type: Boolean,
			default: false
		},
		type: {
			default: "button",
			type: String,
		},
	},
};
</script>

<style lang="scss">
.lib-button2 {
	button {
		background-color: #f88f1f;
		background-image: linear-gradient(
			120deg,
			#eb1b24 -26.95%,
			#ee3423 -11.81%,
			#f15622 12.49%,
			#f47120 37.33%,
			#f68420 62.57%,
			#f88f1f 88.49%,
			#f8931f 116.08%
		);
		border-radius: 4px;
		padding: 10px;
		cursor: pointer;
		font-size: 12px;
		width: 100%;
		color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
		background-size: 200% 100%;
		background-position: 100% 0;
		transition: background-position 0.5s infinite;
		-webkit-transition: background-position 0.5s infinite;
		-moz-transition: background-position 0.5s infinite;
		-o-transition: background-position 0.5s infinite;
		&:hover {
			background-color: #f47120;
			background-position: 0 0;
		}
	}
}

@media (min-width: 390px) {
	.lib-button2 {
		button {
			border-radius: 5.24869px;
			font-size: 13px;
			line-height: 14px;
			height: 34.29px;
		}
	}
}

@media (min-width: 640px) {
	.lib-button2 {
		button {
			font-size: 15.853px;
			line-height: 24px;
			border-radius: 8.61324px;
			height: 56.28px;
		}
	}
}

@media (min-width: 768px) {
	.lib-button2 {
		button {
			border-radius: 10.3359px;
			height: 67.53px;
			font-size: 19.0235px;
			line-height: 29px;
		}
	}
}

@media (min-width: 1025px) {
	.lib-button2 {
		button {
			border-radius: 8px;
			height: 59.02px;
			font-size: 16.6265px;
			line-height: 25px;
		}
	}
}

@media (min-width: 1536px) {
	.lib-button2 {
		button {
			font-size: 16.6265px;
			line-height: 25px;
			height: 59.02px;
			border-radius: 8px;
		}
	}
}
</style>
